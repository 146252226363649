import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export function Button({ action, label }) {
    return (_jsx("div", { className: 'btn', onClick: action, children: label }));
}
export function ButtonLink({ action, label, href, icon, blank, color }) {
    return (_jsx("div", { className: `btnlink ${!action ? 'action' : 'noaction'}`, onClick: action, style: !!color ? { color: color } : {}, children: _jsxs("a", { href: href ? href : undefined, target: blank ? '_blank' : '_self', children: [_jsx("i", { className: icon }), label] }) }));
}
export function Image({ title, src }) {
    return (_jsxs("div", { className: 'lazyimg', children: [_jsx("img", { src: src, loading: 'lazy' }), _jsx("span", { children: title })] }));
}
